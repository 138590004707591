








































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { OrderStatus, SubOrderStatus } from "@/enum";
import {
  dispatch,
  ListQuery,
  Order,
  SubOrder,
  Address,
  ExpressSupplier
} from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
      OrderStatus,
      SubOrderStatus,
      columns: [
        {
          title: "平台订单号",
          key: "order_no",
          dataIndex: "order.order_no",
          scopedSlots: { customRender: "no" },
          width: "240px"
        },
        {
          title: "用户ID",
          dataIndex: "order.user_id",
          width: "80px"
        },
        {
          title: "收货人",
          key: "address_info",
          dataIndex: "sub_orders",
          scopedSlots: { customRender: "address" },
          width: "180px"
        },
        {
          title: "订单状态",
          key: "status",
          dataIndex: "order.status",
          scopedSlots: { customRender: "status" },
          width: "100px"
        },
        {
          title: "支付方式 - 流水号",
          key: "method",
          dataIndex: "payment_info.method",
          customRender: (text: string, record: any) => {
            if (text === "alipay") {
              return "支付宝 - " + record.payment_info.out_trade_no;
            } else if (text === "wechatpay") {
              return "微信 - " + record.payment_info.out_trade_no;
            } else if (text === "miaocoin") {
              return "MIAOCOIN - " + record.payment_info.out_trade_no;
            } else {
              return "-";
            }
          },
          width: "340px",
          align: "center"
        },
        {
          title: "金额",
          key: "amount",
          dataIndex: "amount",
          customRender: (text: string, record: any) =>
            record.order.is_coin_pay
              ? record.order.coin + " MIAOCOIN"
              : "￥" + record.order.amount,
          align: "center"
        },
        {
          title: "优惠金额",
          key: "discount_amount",
          dataIndex: "order.discount_amount",
          customRender: (text: string) => (text ? "￥" + text : "-"),
          align: "center"
        },
        {
          title: "运费",
          key: "shipping",
          dataIndex: "order.shipping",
          customRender: (text: string) => "￥" + text,
          align: "center"
        },
        {
          title: "总计",
          key: "total",
          customRender: (text: string, record: any) =>
            record.order.is_coin_pay
              ? record.order.coin + " MIAOCOIN"
              : "￥" +
                (record.order.amount -
                  record.order.discount_amount +
                  record.order.shipping),
          align: "center"
        },
        {
          title: "下单时间",
          key: "create_time",
          dataIndex: "order.create_time",
          scopedSlots: { customRender: "time" },
          sorter: true
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center"
        }
      ],
      innerColumns: [
        {
          title: "商品订单号",
          key: "sub_order_no",
          dataIndex: "sub_order_no",
          width: "250px"
        },
        {
          title: "商品信息",
          key: "product_snapshot",
          dataIndex: "product_snapshot",
          scopedSlots: { customRender: "product" },
          width: "250px"
        },
        {
          title: "收货人",
          key: "address_info",
          dataIndex: "address_info",
          scopedSlots: { customRender: "address" },
          width: "300px"
        },
        {
          title: "数量",
          key: "number",
          dataIndex: "number",
          align: "center"
        },
        {
          title: "单价",
          key: "amount",
          dataIndex: "amount",
          customRender: (text: string, record: SubOrder) =>
            text ? "￥" + text : record.coin + " MIAOCOIN",
          align: "center"
        },
        {
          title: "优惠金额",
          key: "discount_amount",
          dataIndex: "discount_amount",
          customRender: (text: string) => (text ? "￥" + text : "-"),
          align: "center"
        },
        {
          title: "合计",
          key: "total",
          customRender: (text: string, record: SubOrder) =>
            record.amount
              ? "￥" +
                (
                  record.amount * record.number -
                  record.discount_amount
                ).toFixed(2)
              : (record.coin * record.number).toFixed() + " MIAOCOIN",
          align: "center"
        },
        {
          title: "订单状态",
          key: "status",
          dataIndex: "status",
          scopedSlots: { customRender: "status" }
        },
        {
          title: "修改时间",
          key: "create_time",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" },
          sorter: true
        },
        {
          title: "快递信息",
          key: "express",
          dataIndex: "express",
          scopedSlots: { customRender: "express" }
        },
        {
          key: "actions",
          scopedSlots: { customRender: "actions" }
        }
      ],
      expandedRowKeys: [] as string[]
    };
  },
  computed: {
    ...mapState("app", {
      isTest: "isTest"
    }),
    ...mapState("orders", {
      list: "list",
      query: "query",
      count: "count",
      expressSupplier: "expressSupplier"
    })
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .ordersGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList(this.query);
    },
    onFilterChange(filter: ListQuery) {
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    filterAddress(subOrderList: SubOrder[]) {
      const list: Address[] = [];
      subOrderList.map(subOrder => {
        const item = list.find(
          item => JSON.stringify(item) === JSON.stringify(subOrder.address_info)
        );
        if (!item) {
          list.push(subOrder.address_info);
        }
      });
      return list;
    },
    expressSupplierName(code: string) {
      const expressSupplier = this.expressSupplier.find(
        (item: ExpressSupplier) => item.code === code
      );
      return expressSupplier ? expressSupplier.name : code;
    },
    onLimitDelete(order: Order) {
      this.$confirm({
        title: "确认清除此订单中的锁单商品？",
        content: "锁单商品清除后，用户将可以重新购买订单中的商品。",
        onOk: () => {
          dispatch.ordersLimitDelete(order).then(() => {
            this.$message.success("清除成功");
            this.getList(this.query);
          });
        }
      });
    },
    onSync(order: Order) {
      this.$confirm({
        title: `确认同步订单 “${order.order.order_no}” 到万里牛？`,
        okText: "同步",
        onOk: () => {
          dispatch.ordersSync([order.order.order_no]).then(() => {
            this.$message.success("订单已同步");
          });
        }
      });
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
